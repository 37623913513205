import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import ClosedPlans from './pages/ClosedPlans';
import OpenPlans from './pages/OpenPlans';
import PlanDetails from './pages/PlanDetails';
import TimeControl from './pages/TimeControl';

export const routes = [
	{
		path: '/',
		component: <Dashboard />,
		exact: true
	},
	{
		path: '/time-control',
		component: <TimeControl />
	},
	{
		path: '/open-plans',
		component: <OpenPlans />
	},
	{
		path: '/plan-details/:id',
		component: <PlanDetails />
	},
	{
		path: '/closed-plans',
		component: <ClosedPlans />
	},
	{ 
		path: '/login', 
		component: <Login />
	},
]