import React, { useState, useCallback, useEffect, useRef } from "react";

import { loader } from "helpers";

import { Link, useParams, useHistory } from "react-router-dom";
import styled from 'styled-components';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faMapMarkedAlt, faExclamationCircle, faCamera } from '@fortawesome/free-solid-svg-icons';
import { readAndCompressImage } from 'browser-image-resizer';

const ContainerStyled = styled.div`

	#loading-cristal {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(255,255,255,0.5);
		z-index: 999;

		#loader {
			width: 100px;
		}
	}

	.page-title {
		text-align: center;
		position: relative;

		#back-button {
			position: absolute;
			left: 0;
			top: 5px;
		}
	}

	.property-wrapper {
		position: relative;

		.map-btn {
			position: absolute;
			right: 0;
			bottom: 0;
			color: white;
		}
	}

	.observations,
	.auxiliary-works,
	.optional-proposal {
		position: relative;

		p {
			margin-bottom: 0px;
		}

		ul, ol {
			margin-bottom: 0px;
		}

		.warning-icon {
			position: absolute;
			top: 15px;
			right: 15px;
			color: var(--bs-warning);
			font-size: 30px;
		}
	}

	#take-photo-btn {
		img, 
		svg {
			height: 30px;
			font-size: 25px; 
			float: right;
		}

		input[type=file] {
			opacity: 0;
			width: 0;
			height: 0;
		}
	}

	.accordion {

		.accordion-item {

			.accordion-header {
			 	button {
			 		box-shadow: none !important;

			 		&:not(.collapsed) {
			 			.badge {
			 				background: var(--bs-primary) !important;
			 			}
			 		}
			 	}
			}
		}
	}
`;

let cancelTokenSource = null;

export default function PlanDetails() {	
   	let { id } = useParams();
	let history = useHistory();

   	// Refs
   	let takePhotoRef = useRef(null);

   	// State
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);

	const loadData = useCallback(() => {
		setData(null);
		setLoading(true);

		axios.get('api/workorders/get/' + id, {
			params: {},
		    cancelToken: cancelTokenSource.token
	  	}).then((response) => {
	  		setData(response.data);
	  	}).catch((error) => {
	  		toast.error('Error al cargar los datos, por favor, vuelve a intentarlo');
	  	}).then(() => {
			setLoading(false);
	  	});		
	}, [id]);

	const takePhoto = () => {
		takePhotoRef.current.click();
	};

	const takePhotoFileChangeEvent = async(e) => {
		let file = e.target.files[0];
		if ( !file ) {
			toast.error('No has subido ni sacado ninguna foto');
			return false;
		}

        // Check extension
        let allowed = "png jpg jpeg gif bmp";
        let extension = file.type.substring(file.type.indexOf('/')+1);
        if ( !extension.length || allowed.indexOf(extension) === -1 ) {
            toast.error("Extensión no válida. Solo se permiten ficheros: " + allowed.replaceAll(' ', ', '));
            e.target.value = null;
            return false;
        }

		await readAndCompressImage(file, {
			quality: 0.9,
			maxWidth: 800,
			maxHeight: 600,
			autoRotate: true,
			// debug: true
		}).then(resizedImage => {
			sendImageToServer(resizedImage);
		});

		e.target.value = null;
	}

	const sendImageToServer = (blob) => {
		let formData = new FormData();
		formData.append('file', blob, 'camera photo');

		setLoading(true);

		axios.post('api/workorders/set-pdf-cover/' + id, formData, {
			header: {
				'Content-Type': 'multipart/form-data'
			},
	  		cancelToken: cancelTokenSource.token
	  	}).then((response) => {
	  		setData({...data, pdf_cover: response.data.document ?? null});
	  	}).catch((error) => {
	  		toast.error('Error al enviar la foto, por favor, inténtalo de nuevo');
	  	}).then(() => {
			setLoading(false);
	  	});
	};

	const openDocument = (e, id) => {
		e.preventDefault();

		setLoading(true);

		axios.get('api/workorders/download-document/' + id, {
			params: {},
		    cancelToken: cancelTokenSource.token
	  	}).then(async (response) => {
	  		let base64url = "data:" + response.data.mimetype + ";base64," + response.data.base64;
	  		let blob = await fetch(base64url).then((res) => res.blob());
	  		let url = window.URL.createObjectURL(blob, {type: response.data.mimetype});
	  		window.open(url);
	  	}).catch((error) => {
	  		toast.error('Error al cargar los datos, por favor, vuelve a intentarlo');
	  	}).then(() => {
			setLoading(false);
	  	});	
	}

	const closePlan = () => {
		// Confirm
		const c = window.confirm('¿Seguro que quieres cerrar este plan?');
		if ( !c ) return false;

		setLoading(true);

		axios.post('api/workorders/close/' + id, {}, {
		    cancelToken: cancelTokenSource.token
	  	}).then(async (response) => {
	  		toast.success('El plan de trabajo se ha cerrado');
	  		history.push('/open-plans');
	  	}).catch((error) => {
	  		toast.error('Error al cargar los datos, por favor, vuelve a intentarlo');
	  	}).then(() => {
			setLoading(false);
	  	});	
	};

	useEffect(() => {
		// Set axios cancel token
		cancelTokenSource = axios.CancelToken.source();

		// Load data
		loadData();

		// On unmount, cancel any ajax request
  		return function cleanup() {
           	cancelTokenSource.cancel();
        }
	}, [loadData]);

	// Generate property map url
	let property_map_url = "https://www.google.com/maps/search/?api=1&query=";
	if ( data?.property?.province ) property_map_url += data.property?.province;
	if ( data?.property?.city ) property_map_url += " " + data.property?.city;
	if ( data?.property?.postalcode ) property_map_url += " " + data.property?.postalcode;
	if ( data?.property?.address ) property_map_url += " " + data.property?.address;

	// Render
	return (
		<ContainerStyled className="container">
			{ loading && 
				<div id="loading-cristal">
					{ loading ? loader() : '' }
				</div>
			}
			<div className="row">
				<div className="col-md-12">
					<div className="page-title">
						<Link to="/open-plans" className="btn btn-primary" id="back-button"><FontAwesomeIcon icon={faChevronLeft} /></Link>
						<h4 className="p-0 m-0 py-2">Ficha de plan</h4>	
					</div>
					{ data &&
						<React.Fragment>
							<div className="col-md-12 mt-3">
								<div className="card">
									<div className="card-body">
										<h4 className="m-0 mb-2 text-success">REF.: { data.code }</h4>

										<div><b>Tipo:</b> { data.type === "new" ? "Nueva instalación" : "Mantenimiento" }</div>
				    					<div><b>Fecha:</b> { moment(data.installation_date).format('DD-MM-YYYY') }</div>
				    					
				    					<hr />

				    					<div><b>Inmueble</b></div>
				    					<div className="property-wrapper">
				    						<div>{ data.property?.name }</div>
				    						<div>{ data.property?.address }</div>
				    						<div>{ data.property?.province } { data.property?.city } { data.property?.postalcode }</div>
				    						<div>{ data.property?.contact_person }</div>
				    						<div><a href={'tel:' + data.property?.phone}>{ data.property?.phone }</a></div>
				    						<a href={property_map_url} target="_blank" rel="noreferrer" className="btn btn-primary btn-lg map-btn"><FontAwesomeIcon icon={faMapMarkedAlt} /></a>
				    					</div>

				    					<hr />
				    					
				    					<div><b>Cliente</b></div>
				    					<div>
				    						<div>{ data.client?.name }</div>
				    						<div>{ data.client?.address }</div>
				    						<div>{ data.client?.province } { data.client?.city } { data.client?.postalcode }</div>
				    						<div><a href={'tel:' + data.client?.phone}>{ data.client?.phone }</a></div>
				    					</div>				    					
				    					
				    					<hr />

				    					<div><b>Obra</b></div>
				    					<div>
				    						<div>Franja horaria: { data.construction?.time }</div>
				    						<div>Persona de contacto: { data.construction?.name }</div>
				    						<div><a href={'tel:' + data.construction?.phone}>{ data.construction?.phone }</a></div>
				    					</div>
		    						</div>
		    					</div>
							</div>

							{ data.observations &&
								<div className="col-md-12 mt-3 observations">
									<div className="card">
										<div className="card-body">
											<h4 className="m-0 mb-2 text-primary">Observaciones</h4>
											<FontAwesomeIcon className="warning-icon" icon={faExclamationCircle} />
											<div dangerouslySetInnerHTML={{__html: data.observations}}></div>
										</div>
									</div>
								</div>
							}

							{ data.auxiliary_works &&
								<div className="col-md-12 mt-3 auxiliary-works">
									<div className="card">
										<div className="card-body">
											<h4 className="m-0 mb-2 text-primary">Trabajos auxiliares</h4>
											<FontAwesomeIcon className="warning-icon" icon={faExclamationCircle} />
											<div dangerouslySetInnerHTML={{__html: data.auxiliary_works}}></div>
										</div>
									</div>
								</div>
							}

							{ data.optional_proposal &&
								<div className="col-md-12 mt-3 optional-proposal">
									<div className="card">
										<div className="card-body">
											<h4 className="m-0 mb-2 text-primary">Propuesta opcional</h4>
											<FontAwesomeIcon className="warning-icon" icon={faExclamationCircle} />
											<div dangerouslySetInnerHTML={{__html: data.optional_proposal}}></div>
										</div>
									</div>
								</div>
							}

							<div className="col-md-12 mt-3">
								<div className="my-3">
									<button className={"btn w-100 btn-"+(data && !data.pdf_cover ? 'danger' : 'success')} id="take-photo-btn" onClick={() => takePhoto()}>
										{ data && !data.pdf_cover ? 'SACAR FOTO FACHADA' : 'SACAR FOTO FACHADA NUEVA' }
										&nbsp;
										{ data && data.pdf_cover ? 
											<img src={'data:' + data.pdf_cover.mimetype + ';base64,' + data.pdf_cover.base64} alt="pdf_cover" /> 
											: 
											<FontAwesomeIcon icon={faCamera} /> 
										}
										<input type="file" ref={takePhotoRef} onChange={(e) => takePhotoFileChangeEvent(e)} />
									</button>
								</div>

								<div className="d-flex justify-content-between">
									<div className="dropdown w-50 me-2">
										<button className="btn btn-primary w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
											PLANOS
											<span className="badge bg-warning ms-2 text-dark">
												{ data && data.blueprints && data.blueprints.length > 0 ? '(' + data.blueprints.length + ')' : '' }
											</span>
										</button>
										<ul className="dropdown-menu">
											{ data && data.blueprints && 
												data.blueprints.map((item, idx) => {
													return <li key={idx}><a className="dropdown-item" href="." onClick={(e) => openDocument(e, item.id)}>{ item.name }</a></li>;
												})
											}
										</ul>
									</div>
									
									<div className="dropdown w-50 ms-2">
										<button className="btn btn-primary w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
											ADJUNTOS
											<span className="badge bg-warning ms-2 text-dark">
												{ data && data.attachments && data.attachments.length > 0 ? '(' + data.attachments.length + ')' : '' }
											</span>
										</button>
										<ul className="dropdown-menu">
											{ data && data.attachments && 
												data.attachments.map((item, idx) => {
													return <li key={idx}><a className="dropdown-item" href="." onClick={(e) => openDocument(e, item.id)}>{ item.name }</a></li>;
												})
											}
										</ul>
									</div>
								</div>
							</div>

							<div className="col-md-12 mt-3 mb-3">
								<div className="accordion">
									{ data && data.items && 
										data.items.map((el, idx) => {
											let rows = [];
											for(let i=1; i<=el.units; i++) {
												rows.push(
													<AccordionItem 
														key={el.id + '-' + i} 
														el={el} 
														i={i} 
														setLoading={(status) => setLoading(status)} 

														data={data}
														setData={(data) => setData(data)}
													/>
												);
											}
											return rows;
										})
									}
								</div>
							</div>

							<div className="col-md-12 mt-3 mb-3">
								<button className="btn btn-danger w-100" onClick={() => closePlan()}>CERRAR PLAN</button>
							</div>
						</React.Fragment>
					}
				</div>
			</div>
		</ContainerStyled>
	);
}












const AccordioItemStyled = styled.div`
	.item-document {
		margin: 5px;
	}

	.image-row {
		position: relative;
		width: 100%;
		padding: 10px;
		margin: 5px;
		border-radius: 5px;
		border: 1px solid lightgray;
		text-align: center;

		img {
			max-width: 100px;
		}

		.observations {
			text-align: left;
			display: block;
			margin-top: 5px;
			width: 100%;
		}

		.remove {
			color: var(--bs-danger);
			text-decoration: none;
			position: absolute;
			top: 5px;
			right: 5px;
			font-size: 25px;
			font-weight: bold;
		}
	}

	table {
		table-layout: auto !important;
		width: 100%;

		tr {
			th, td {
				vertical-align: middle;
				padding: 0px;
				text-align: center;
				width: 60px;

				&:last-of-type {
					text-align: left;
					width: unset !important;
					font-size: 12px;
					line-height: 12px;
				}

				.form-check-input {
					margin: 0 auto;
				}

				.form-switch {
					padding-left: 0;
					display: inline-block;
					min-height: unset;
					padding-top: 8px;
				}
			}
		}
	}

	.takePhotoFile {
		opacity: 0;
		width: 0;
		height: 0;
	}
`;


let imageObservationsTimeout = null;
export function AccordionItem(props) {
	let takePhotoRef = useRef(null);

	let [opened, setOpened] = useState(false);
	let [images, setImages] = useState({});

	// Prepare checks
	let checksToLoop = null;
	let imagesToLoop = null;
	try { checksToLoop = props.el.checks[props.i].checks; } catch (e) {}
	try { imagesToLoop = props.el.checks[props.i].images; } catch (e) {}
	let checksRows = [];
	let imagesRows = [];

	useEffect(() => {
		if ( !opened ) return;

		for(let idx in imagesToLoop) {
			// CHeck if image is loaded 
			let index = props.el.id + '-' + props.i + '-' + idx;
			if ( images[index] !== undefined ) continue;

			// Get image
			const getData = async () => {
				await axios.get('api/workorders/download-checks-image/'+props.el.id+'/'+props.i+'/'+idx, {
					cancelToken: cancelTokenSource.token,
					responseType: 'blob',
				}).then(async (response) => {
					let dataUrl = window.URL.createObjectURL(response.data, {type: response.headers['content-type']});
					setImages((prev) => ({...prev, [index]: dataUrl}))
				})
			}
			getData();
		}
	}, [opened, imagesToLoop]);

	const openDocument = (e, itemId, id) => {
		e.preventDefault();

		props.setLoading(true);

		axios.get('api/workorders/download-item-document/' + itemId + '/' + id, {
			params: {},
		    cancelToken: cancelTokenSource.token
	  	}).then(async (response) => {
	  		let base64url = "data:" + response.data.mimetype + ";base64," + response.data.base64;
	  		let blob = await fetch(base64url).then((res) => res.blob());
	  		let url = window.URL.createObjectURL(blob, {type: response.data.mimetype});
	  		window.open(url);
	  	}).catch((error) => {
	  		toast.error('Error al cargar los datos, por favor, vuelve a intentarlo');
	  	}).then(() => {
			props.setLoading(false);
	  	});	
	}

	const setCheck = (e, status, itemId, unit, checkIdx) => {
		props.setLoading(true);

		axios.post('api/workorders/set-check/' + itemId + '/' + unit + '/' + checkIdx, {
			status: status
		}, {
		    cancelToken: cancelTokenSource.token
	  	}).then(async (response) => {
	  		
	  	}).catch((error) => {
	  		e.target.checked = !e.target.checked;
	  		toast.error('Error al marcar el check, vuelve a intentarlo');
	  	}).then(() => {
			props.setLoading(false);
	  	});	
	}


	const takePhoto = () => {
		takePhotoRef.current.click();
	};

	const takePhotoFileChangeEvent = async(e) => {
		let file = e.target.files[0];
		if ( !file ) {
			toast.error('No has subido ni sacado ninguna foto');
			return false;
		}

        // Check extension
        let allowed = "png jpg jpeg gif bmp";
        let extension = file.type.substring(file.type.indexOf('/')+1);
        if ( !extension.length || allowed.indexOf(extension) === -1 ) {
            toast.error("Extensión no válida. Solo se permiten ficheros: " + allowed.replaceAll(' ', ', '));
            e.target.value = null;
            return false;
        }

		await readAndCompressImage(file, {
			quality: 0.9,
			maxWidth: 800,
			maxHeight: 600,
			autoRotate: true,
			// debug: true
		}).then(resizedImage => {
			sendImageToServer(resizedImage);
		});

		e.target.value = null;
	}


	const sendImageToServer = (blob) => {
		let formData = new FormData();
		formData.append('file', blob, 'camera photo');

		props.setLoading(true);

		axios.post('api/workorders/upload-checks-image/' + props.el.id + '/' + props.i, formData, {
			header: {
				'Content-Type': 'multipart/form-data'
			},
	  		cancelToken: cancelTokenSource.token
	  	}).then((response) => {
	  		let items = props.data.items;
	  		let itemIdx = props.data.items.findIndex((el) => el.id === props.el.id);
	  		items[itemIdx].checks = response.data.checks;
	  		props.setData({...props.data, items: items});
	  	}).catch((error) => {
	  		toast.error('Error al enviar la foto, por favor, inténtalo de nuevo');
	  	}).then(() => {
			props.setLoading(false);
	  	});
	};


	const removeImage = (e, itemId, unit, imageIdx) => {
		e.preventDefault();

		let c = window.confirm('¿Seguro que quieres eliminar esta imagen?');
		if ( !c ) return false;

		props.setLoading(true);

		axios.post('api/workorders/delete-checks-image/' + itemId + '/' + unit + '/' + imageIdx, {
		}, {
		    cancelToken: cancelTokenSource.token
	  	}).then(async (response) => {
	  		let items = props.data.items;
	  		let itemIdx = props.data.items.findIndex((el) => el.id === itemId);
	  		items[itemIdx].checks = response.data.checks;
	  		props.setData({...props.data, items: items});
	  	}).catch((error) => {
	  		toast.error('Error al borrar la imagen, vuelve a intentarlo');
	  	}).then(() => {
			props.setLoading(false);
	  	});	
	};

	const updateImageObservations = (e, itemId, unit, imageIdx) => {
		if ( imageObservationsTimeout ) clearTimeout(imageObservationsTimeout);

		let text = e.target.value;
		imageObservationsTimeout = setTimeout(() => {
			axios.post('api/workorders/update-checks-image-observations/' + itemId + '/' + unit + '/' + imageIdx, {
				observations: text
			}, {
			    cancelToken: cancelTokenSource.token
		  	}).then(async (response) => {

		  	}).catch((error) => {
		  		e.target.checked = !e.target.checked;
		  		toast.error('Error al grabar las observaciones, vuelve a intentarlo');
		  	});	
		}, 1000);
	};

	if ( checksToLoop ) {
		for(let idx in checksToLoop) {
			let check = checksToLoop[idx];
			checksRows.push(
				<tr key={'radio-' + props.el.id + '-' + props.i + '-' + idx}>
					<td>
						<div className="form-check form-switch">
							<input className="form-check-input" type="radio" name={'radio-' + props.el.id + '-' + props.i + '-' + idx} onChange={(e) => setCheck(e, 'good', props.el.id, props.i, idx)} defaultChecked={check.status === "good"} />
						</div>
					</td>
					<td>
						<div className="form-check form-switch">
							<input className="form-check-input" type="radio" name={'radio-' + props.el.id + '-' + props.i + '-' + idx} onChange={(e) => setCheck(e, 'bad', props.el.id, props.i, idx)} defaultChecked={check.status === "bad"} />
						</div>
					</td>
					<td>
						<div className="form-check form-switch">
							<input className="form-check-input" type="radio" name={'radio-' + props.el.id + '-' + props.i + '-' + idx} onChange={(e) => setCheck(e, 'nothing', props.el.id, props.i, idx)} defaultChecked={check.status === "nothing"} />
						</div>
					</td>
					<td>{ check.text }</td>
				</tr>
			);
		}
	}

	if ( imagesToLoop ) {
		for(let idx in imagesToLoop) {
			let image = imagesToLoop[idx];
			imagesRows.push(
				<div id={'image-' + props.el.id + '-' + idx} key={'image-' + props.el.id + '-' + idx} className="image-row">
					<img src={images[props.el.id+'-'+props.i+'-'+idx] ?? ''} alt={idx} />
					<textarea className="observations" placeholder={'Escribe aquí un comentario'} onChange={(e) => updateImageObservations(e, props.el.id, props.i, idx)} defaultValue={image.observations}></textarea>
					<a href="." className="remove" onClick={(e) => removeImage(e, props.el.id, props.i, idx)}>&times;</a>
				</div>
			);
		}
	}

	// Prepare documents
	let documents = [];
	for(let idx in props.el.documents) {
		let el = props.el.documents[idx];
		documents.push(
			<button key={el.idx + '-' + el.id} className="btn btn-secondary btn-sm item-document" onClick={(e) => openDocument(e, props.el.id, el.id)}>
				{ el.name }
			</button>
		);
	}

	return (
		<AccordioItemStyled className={'accordion-item mb-2 ' + (opened ? '' : 'collapsed')}>
			<h2 className="accordion-header" onClick={(e) => {e.preventDefault(); setOpened(!opened)}}>
		      	<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#item-' + props.el.id + '-' + props.i}>
		        	<span className="badge bg-secondary">{ props.el.reference_array[props.i] }</span>&nbsp;
		        	{ props.el.concept } { props.el.model?.name }
		      	</button>
		    </h2>
		    <div id={'item-' + props.el.id + '-' + props.i} className={'accordion-collapse collapse' + (opened ? 'show' : '')}>
		      	<div className="accordion-body">
		        	<div>{ documents }</div>

		        	<div>
		        		{ checksRows.length > 0 ? 
			        		<table className="table">
			        			<thead>
			        				<tr>
			        					<th>Bien</th>
			        					<th>Mal</th>
			        					<th>N/P</th>
			        					<th></th>
			        				</tr>
			        			</thead>
			        			<tbody>
						        	{ checksRows }
					        	</tbody>
			        		</table>
			        		:
			        		<div className="text-danger">No hay checks para revisar. Si crees que esto es un error, por favor, contacta con el administrador</div>
			        	}
		        	</div>

		        	<div>
		        		{ imagesRows } 

		        		<button className="btn btn-primary btn-sm" onClick={() => takePhoto()}>Añadir foto</button>
		        		<input type="file" className="takePhotoFile" onChange={(e) => takePhotoFileChangeEvent(e)} ref={takePhotoRef} />
		        	</div>
		        </div>
		    </div>
		</AccordioItemStyled>
	);
}