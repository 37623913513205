import create from "zustand";

// Create reducer
const reducer = (state, action) => {
	if ( action.type === "setUser" ) {
		localStorage.setItem('user', JSON.stringify(action.data ?? ""));
		return {user: action.data};
	}

	if ( action.type === "setToken" ) {
		localStorage.setItem('token', action.data ?? "");
		return {token: action.data};
	}

	// Not valid type
	throw new Error();
}

// Default state
const defaultState = {
	api_url: (() => {
		let hostname = window.location.hostname;
		if ( hostname.indexOf('.local') !== -1 || hostname.indexOf('localhost') !== -1 || hostname.indexOf('192.168') !== -1 ) return 'https://'+hostname+':90';
		return 'https://presupuestos.europeanprof.es';
	})(),
	user: (() => {
		try {
			return JSON.parse( localStorage.getItem('user') );
		} catch (err) {
			return null;
		}
	})(),
	token: localStorage.getItem('token'),
};

// Create hook
const useStore = create(set => ({
	...defaultState,
	dispatch: (action) => set(state => reducer(state, action)),
}));

export default useStore;