import React, { useEffect, useCallback, useState } from "react";
import { loader } from "helpers";
import Paginator from 'components/Paginator';

import { Link } from "react-router-dom";
import styled from 'styled-components';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const ContainerStyled = styled.div`
	
	#loader {
		width: 100px;
	}

	.workorder {

		.card-header {

			.card-title {
				margin-bottom: 0;
				text-align: left !important;
			}
		}

		.card-body {
			text-align: left !important;
		}
	}

	.page-title {
		text-align: center;
		position: relative;

		#back-button {
			position: absolute;
			left: 0;
			top: 5px;
		}
	}
`;

let cancelTokenSource = null;

export default function ClosedPlans() {
    // State
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);

	const loadData = useCallback((page=1) => {
		setData(null);
		setLoading(true);

		axios.get('api/workorders/list', {
			params: {
				status: 'closed',
				sort: 'installation_date',
				direction: 'desc',
				page: page
			},
		    cancelToken: cancelTokenSource.token
	  	}).then((response) => {
	  		setData(response.data);
	  	}).catch((error) => {
	  		toast.error('Error al cargar los datos, por favor, vuelve a intentarlo');
	  	}).then(() => {
			setLoading(false);
	  	});		
	}, []);

	useEffect(() => {
		// Set axios cancel token
		cancelTokenSource = axios.CancelToken.source();

		// Load data
		loadData();

		// On unmount, cancel any ajax request
  		return function cleanup() {
           	cancelTokenSource.cancel();
        }
	}, [loadData]);
    
    // Prepare rows
    let rows = [];
    if ( data && data.data && data.data.length ) {
    	for(let idx in data.data) {
    		let el = data.data[idx];
    		rows.push(
    			<div key={idx} className="card mb-2 workorder">
    				<div className="card-header text-white bg-secondary">
    					<h5 className="card-title">REF.: { el.code }</h5>
    				</div>
    				<div className="card-body">
    					<div><b>Tipo:</b> { el.type === "new" ? "Nueva instalación" : "Mantenimiento" }</div>
    					<div><b>Fecha:</b> { moment(el.installation_date).format('DD-MM-YYYY') }</div>
    					<div><b>Cliente:</b> { el.client?.name }</div>
    					<div><b>Teléfono:</b> { el.client?.phone }</div>
    					<div><b>Inmueble:</b> {el.id}</div>
    				</div>
    			</div>
    		);
    	}
    }
    
	// Render
	return (
		<ContainerStyled className="container">
			<div className="row">
				<div className="col-md-12">
					<div className="page-title">
						<Link to="/" className="btn btn-primary" id="back-button"><FontAwesomeIcon icon={faChevronLeft} /></Link>
						<h4 className="p-0 m-0 py-2">Planes cerrados</h4>	
					</div>
				</div>
					
				{ data && data.data && data.data.length > 0 &&
					<div className="d-flex justify-content-center mt-1 mb-2">
						<Paginator
							min={1}
							current={data?.current_page}
							max={data?.last_page}
							changeCallback={(page) => loadData(page)}
							disabled={!rows.length}
						/>
					</div>
				}
						
				<div className="col-md-12 text-center">
					{ rows.length ? rows : '' }
					{ data && data.data && data.data.length === 0 ? <div className="mt-4">No hay información para mostrar</div> : '' }
					{ loading ? loader() : '' }
				</div>

				{ data && data.data && data.data.length > 0 &&
					<div className="d-flex justify-content-center mt-2 mb-1">
						<Paginator
							min={1}
							current={data?.current_page}
							max={data?.last_page}
							changeCallback={(page) => loadData(page)}
							disabled={!rows.length}
						/>
					</div>
				}				
			</div>
		</ContainerStyled>	
	);
}