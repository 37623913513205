import React, { useEffect, useCallback, useState, useRef } from "react";
import { loader } from "helpers";

import { Link, Redirect } from "react-router-dom";
import styled from 'styled-components';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const ContainerStyled = styled.div`
	
	#loader {
		width: 100px;
	}

	.page-title {
		text-align: center;
		position: relative;
		margin-bottom: 40px;

		#back-button {
			position: absolute;
			left: 0;
			top: 5px;
		}
	}

	.buttons-wrapper {

		.btn {
			padding: 30px 20px;
			font-size: 30px;
		}

		.last_in,
		.last_out {
			text-align: center;
			margin-bottom: 20px;

			.title {
				color: var(--bs-primary);
				font-weight: bold;
				font-size: 25px;
				line-height: 22px;
			}

			.time {
				font-weight: 500;
				font-size: 22px;
			}

			.reason {
				font-size: 18px;
			}
		}

		.radios-wrapper {
			label {
				display: flex;
				align-items: center;
				margin-bottom: 15px;
				font-size: 25px;

				input {
					width: 30px;
					height: 30px;
					margin-right: 10px;
				}
			}
		}
	}
`;

let cancelTokenSource = null;

export default function TimeControl() {
    // State
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [reason, setReason] = useState('');
	const [otherReasonOpened, setOtherReasonOpened] = useState(false);
	const [redirect, setRedirect] = useState(false);

	// Refs
	const reasonInputTextRef = useRef(null);

	const loadData = useCallback(() => {
		setData(null);
		setLoading(true);

		axios.get('api/time-control/get-last', {
			params: {},
		    cancelToken: cancelTokenSource.token
	  	}).then((response) => {
	  		setData(response.data);
	  	}).catch((error) => {
	  		toast.error('Error al cargar los datos, por favor, vuelve a intentarlo');
	  	}).then(() => {
			setLoading(false);
	  	});		
	}, []);

	const getCoords = async () => {
        const pos = await new Promise((resolve, reject) => {
      		navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        return {
          	long: pos.coords.longitude,
          	lat: pos.coords.latitude,
        };
    };

	const sendAction = async (e, action) => {
		e.preventDefault();

		// Get position
	    let position = null;
	    try {
			position = await getCoords();
		} catch (err) {
			if ( err.code === 1 ) toast.error('Debes aceptar los permisos para realizar la geolocalización');
			else toast.error('Error: ' + err.message);
			setLoading(false);
			return false;
		}

		// Check reason
		if ( !reason.replace(/\s/g,'').length && action === "out" ) {
			toast.error('Debes elegir un motivo de salida');
			return false;
		}

		setLoading(true);

		// Ajax
		axios.post('api/time-control/do-action/' + action, {
			reason: reason,
			latitude: position.lat,
			longitude: position.long
		}, {
			cancelToken: cancelTokenSource.token
		}).then((response) => {
			setData(response.data.last);
	  		setRedirect(true);
	  	}).catch((error) => {
	  		toast.error('Error al enviar los datos, por favor, vuelve a intentarlo');
	  	}).then(() => {
			setLoading(false);
	  	});		
	};

	useEffect(() => {
		// Set axios cancel token
		cancelTokenSource = axios.CancelToken.source();

		// Load data
		loadData();

		// On unmount, cancel any ajax request
  		return function cleanup() {
           	cancelTokenSource.cancel();
        }
	}, [loadData]);
    
    useEffect(() => {
    	if ( otherReasonOpened ) reasonInputTextRef.current.focus();
    }, [otherReasonOpened]);
    
	// Prepare last_in
	let last_in_element = null;
	let date_in_str = "";
	if ( data && data.action === "in" ) {
		let date = moment(data.created_at);
		let today = moment().startOf('day');
		let yesterday = moment().subtract(1, 'days').startOf('day');

		if ( date.isSame(today, "d") ) date_in_str = "Hoy";
		if ( date.isSame(yesterday, "d") ) date_in_str = "Ayer";
		if ( !date_in_str ) date_in_str = date.format('DD-MM-YYYY');
		if ( date_in_str ) date_in_str = date_in_str + ' a las ' + date.format('HH:mm');
	}
	if ( date_in_str ) {
		last_in_element = 	<div className="last_in">
								<div className="title">Última entrada</div>
								<div className="time">{ date_in_str }</div>
							</div>;
	}


	// Prepare last_out
	let last_out_element = null;
	let date_out_str = "";
	if ( data && data.action === "out" ) {
		let date = moment(data.created_at);
		let today = moment().startOf('day');
		let yesterday = moment().subtract(1, 'days').startOf('day');

		if ( date.isSame(today, "d") ) date_out_str = "Hoy";
		if ( date.isSame(yesterday, "d") ) date_out_str = "Ayer";
		if ( !date_out_str ) date_out_str = date.format('DD-MM-YYYY');
		if ( date_out_str ) date_out_str = date_out_str + ' a las ' + date.format('HH:mm');
	}
	if ( date_out_str ) {
		last_out_element = 	<div className="last_out">
								<div className="title">Última salida</div>
								<div className="time">{ date_out_str }</div>
								<div className="reason">{ data.reason ? data.reason : ''}</div>
							</div>;
	}

	// Show toast before redirect
	useEffect(() => {
		if ( data ) toast.success((data.action === 'in' ? 'Entrada' : 'Salida') + ' realizada');
	}, [redirect]);

	// Redirect
	if ( redirect ) return <Redirect to="/" />;
	
	// Render
	return (
		<ContainerStyled className="container">
			<div className="row">
				<div className="col-md-12">
					<div className="page-title">
						<Link to="/" className="btn btn-primary" id="back-button"><FontAwesomeIcon icon={faChevronLeft} /></Link>
						<h4 className="p-0 m-0 py-2">Horario</h4>	
					</div>
				</div>

				{ loading && loader() }
				{ !loading &&
					<div className="col-md-12 buttons-wrapper">
						{ (data && data.action === "in") && last_in_element }
						{ (data && data.action === "out") && last_out_element }

						{ (!data || data.action === "out") &&
							<a href="." className="btn btn-primary btn-lg d-block mb-2 in" onClick={(e) => sendAction(e, 'in')}>
								ENTRAR
							</a>
						}
						{ (data && data.action === "in") &&
							<React.Fragment>
								<hr />
								<div className="radios-wrapper">
									<label><input type="radio" name="reason" onChange={(e) => { setReason('Comida'); setOtherReasonOpened(false); }} /> Comida</label>
									<label><input type="radio" name="reason" onChange={(e) => { setReason('Fin jornada'); setOtherReasonOpened(false); }} /> Fin de jornada</label>
									<label><input type="radio" name="reason" onChange={(e) => { setReason('Médico'); setOtherReasonOpened(false); }} /> Médico</label>
									<label><input type="radio" name="reason" onChange={(e) => { setReason('Permiso'); setOtherReasonOpened(false); }} /> Permiso</label>
									<label><input type="radio" name="reason" onChange={(e) => { setReason(''); setOtherReasonOpened(true); }} /> Otro motivo</label>
									{ otherReasonOpened &&
										<input type="text" ref={reasonInputTextRef} name="reason" onChange={(e) => setReason(e.target.value)} className="form-control form-control-lg mb-3" placeholder="Escribe el motivo" />
									}
								</div>
								<a href="." className="btn btn-danger btn-lg d-block mb-2 out" onClick={(e) => sendAction(e, 'out')}>
									SALIR
								</a>
							</React.Fragment>
						}
					</div>
				}	
			</div>
		</ContainerStyled>	
	);
}