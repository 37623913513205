import React from "react";
import {
    BrowserRouter as Router,
    Switch, 
    Route,
    Redirect
} from "react-router-dom";
import { routes } from 'routes';
import useStore from "store";
import axios from 'axios';
import { ToastContainer } from 'react-toastify';

function App() {
    // State
    const dispatch = useStore(state => state.dispatch);
    const api_url = useStore(state => state.api_url);
    const user = useStore(state => state.user);
    const token = useStore(state => state.token);
    
    // Axios global
    axios.defaults.baseURL = api_url ?? null;
    axios.defaults.headers.common['Authorization'] = token ? 'Bearer ' + token : null;

    // Axios interceptors
    axios.interceptors.response.use((response) => {
        // Do something with response data
        return response;
    }, (error) => {
        // Check unauthorized error
        if ( error.response && error.response.status === 401 ) {
            dispatch({type: 'setToken', data: ""});
            dispatch({type: 'setUser', data: ""});
        }

        // Default
        return Promise.reject(error);
    });

    // Render
    return (
        <React.Fragment>
            <ToastContainer
                autoClose={3000}
            />
            <Router>
                { (!user || !token) &&
                    <Redirect to="/login" />
                }   
                
                <Switch>
                    {routes.map((route, i) => <Route key={i} path={route.path} exact={route.exact ?? false}>{route.component}</Route>)}
                </Switch>   
            </Router>
        </React.Fragment>
    );
}

export default App;