import React, { useState, useEffect, useCallback } from "react";
import useStore from "store";

import { Link } from "react-router-dom";
import axios from 'axios';

let cancelTokenSource = null;

export default function Dashboard() {
    // State
	const dispatch = useStore(state => state.dispatch);
	const user = useStore(state => state.user);
	const [lastTimeControl, setLastTimeControl] = useState(null);

	const getLastTimeControl = useCallback(() => {
		axios.get('api/time-control/get-last', {
			params: {},
		    cancelToken: cancelTokenSource.token
	  	}).then((response) => {
	  		setLastTimeControl(response.data);
	  	}).catch((error) => {

	  	});	
	}, []);

	useEffect(() => {
		// Set axios cancel token
		cancelTokenSource = axios.CancelToken.source();

		// Load last time control
		getLastTimeControl();

		// On unmount, cancel any ajax request
  		return function cleanup() {
           	cancelTokenSource.cancel();
        }
	}, [getLastTimeControl])

    const logout = () => {
    	dispatch({type: 'setUser', data: ''});
    	dispatch({type: 'setToken', data: ''});
    };

    // Last time control icon
    let lastTimeControlIcon = "⚠";
    if ( lastTimeControl && lastTimeControl.action == "in" ) lastTimeControlIcon = "🕐";

	// Render
	return (
		<div className="container">
			<div className="row">
				<div className="col-md-12 text-center">
					<h4 className="p-0 m-0 my-3">Hola, { user?.name }</h4>	
				</div>
				<div className="col-md-12">
					<Link to="/time-control" className="btn btn-primary btn-lg d-block mb-2">
						HORARIO
						{ lastTimeControlIcon }
					</Link>
					<Link to="/open-plans" className="btn btn-success btn-lg d-block mb-2">PLANES ABIERTOS</Link>
					<Link to="/closed-plans" className="btn btn-secondary btn-lg d-block mb-5">PLANES CERRADOS</Link>
					<a href="." className="btn btn-danger btn-lg d-block" onClick={() => logout()}>CERRAR SESIÓN</a>
				</div>
			</div>
		</div>	
	);
}