import React, { useState, useRef } from "react";
import { Redirect } from "react-router-dom";
import useStore from "store";
import axios from 'axios';

export default function Login() {
    // Global state
    const dispatch = useStore(state => state.dispatch);
    const user = useStore(state => state.user);
    const token = useStore(state => state.token);

	// Local state
	const [ loginError, setLoginError ] = useState(null);

	// Refs
	const emailRef = useRef(null);
	const passwordRef = useRef(null);

	function setUser(e) {
		e.preventDefault();

		// Remove error
		setLoginError(null);

		// Do login
		axios.post('api/get-token', {
		    email: emailRef.current.value,
		    password: passwordRef.current.value
	  	})
	  	.then((response) => {
	  		// Set token
		    if ( response.data.token ) {
		    	dispatch({
					type: 'setToken', 
					data: response.data.token
				});
			}

			// Set user
		    if ( response.data.user ) {
		    	dispatch({
					type: 'setUser', 
					data: response.data.user
				});
		    }
	  	})
	  	.catch((error) => {
	  		// Set error
		    setLoginError('Datos de acceso no válidos');
	  	});		
	}

	// Check logged
	if ( user && token ) {
		return <Redirect to="/"></Redirect>;
	}

	// Render
	return (
		<div className="container">
			<div className="row justify-content-center align-items-center">
				<div className="col-md-6">
					<div className="card mt-3">
						<div className="card-body">
							<div className="text-center">
								<h3 className="text-primary">OPERARIOS</h3>
							</div>
							<div className="login-form">
								<form action="">
									<div className="mb-2">
										<input ref={emailRef} placeholder="Email" type="email" className="form-control form-control-sm" />
									</div>
									<div className="mb-2">
										<input ref={passwordRef} placeholder="Contraseña" type="password" className="form-control form-control-sm" />
									</div>
									<div className="d-flex flex-column d-justify-content-center">
										<button className="btn btn-sm btn-primary font-weight-bold" onClick={(e) => setUser(e)}>INICIAR SESIÓN</button>

										{ loginError &&
											<div className="invalid-feedback d-block text-center mt-2 p-2 bg-danger text-white rounded">{loginError}</div>
										}
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>	
	);
}